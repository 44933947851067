import React from 'react';
import { BoxProps } from '@leagueplatform/genesis-commons';
import { APP_NAV_DEFAULT_HEIGHT } from '@highmark-web/highmark-common';
import {
  AppBarComponent,
  AppBarItem,
  UserDropdownComponent,
} from '@leagueplatform/web-common-components';
import HighmarkIcon from 'assets/highmark-icon.svg';
import { Icon } from '@leagueplatform/genesis-core';
import { useAgentName } from 'components/hooks/use-agent-name.hook';

const userDropdownConfig = [
  [
    {
      to: '/sign-out',
      text: 'LOG_OUT',
      linkProps: {
        color: 'onSurface.text.critical',
      } as BoxProps,
    },
  ],
];

export const AppNavBarComponent = React.memo(() => {
  const { fullName } = useAgentName();

  return (
    <AppBarComponent navHeight={APP_NAV_DEFAULT_HEIGHT}>
      <AppBarItem flexGrow={1} aria-hidden="true" />

      <AppBarItem margin="auto">
        <Icon icon={HighmarkIcon} size="$two" />
      </AppBarItem>

      <AppBarItem flexGrow={50} aria-hidden="true" />

      <AppBarItem>
        <UserDropdownComponent
          dropdownItemsConfig={userDropdownConfig}
          label={fullName}
        />
      </AppBarItem>

      <AppBarItem flexGrow={1} aria-hidden="true" />
    </AppBarComponent>
  );
});
